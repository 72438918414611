<template>
  <div class="container">
    <div class="face-login">
      <div class="top">
        <div class="left-lable">IP地址：</div>
        <div class="right-lable">
          <select v-model="value" placeholder="请选择">
            <option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></option>
          </select>
        </div>
      </div>
      <div class="body">
        <div class="img">
          <img src="@/assets/images/login/face_kuang.png" alt="" />
          <div class="mask">
            <video
              id="videoCamera"
              :width="videoWidth"
              :height="videoHeight"
              :x5-video-player-fullscreen="true"
              autoplay
            ></video>
            <canvas
              style="display: none"
              id="canvasCamera"
              :width="videoWidth"
              :height="videoHeight"
            ></canvas>
          </div>
        </div>
      </div>
      <div class="footer" @click="setImage()">刷脸识别登录进入平台</div>
      <div class="login" @click="loginHandle">账号登录</div>
    </div>
  </div>
</template>
<script>
import faceLogin from "../../https/login/login";
export default {
  name: "",
  data() {
    return {
      // 视频调用相关数据开始
      videoWidth: 300,
      videoHeight: 300,
      imgSrc: "",
      thisCancas: null,
      thisContext: null,
      thisVideo: null,
      openVideo: false,
      //视频调用相关数据结束
      postVideoImg: "", // 图片上传后获取的url链接
      options: [
        {
          value: "192.168.1.1",
          label: "192.168.1.1",
        },
        {
          value: "192.168.1.2",
          label: "192.168.1.2",
        },
        {
          value: "192.168.1.3",
          label: "192.168.1.3",
        },
        {
          value: "192.168.1.4",
          label: "192.168.1.4",
        },
      ],
      value: "",
      // username: this.$store.state.state.userInfo
      //   ? this.$store.state.state.userInfo.role_name
      //   : '',
    };
  },
  created() {},
  computed: {},
  mounted() {
    // 第一步打开摄像头
    this.getCompetence(); //调用摄像头
  },

  methods: {
    // 第三步、拍照图转换file格式上传，
    // 第四步、获取图片url链接
    async postImg() {
      // console.log(this.$store.state.state.userInfo.id);
      let that = this;
      let formData = new FormData();
      formData.append("pic", this.base64ToFile(this.imgSrc, "png"));
      formData.append("ip", this.value); // 额外参数
      // console.log(formData, 66666);
      // 使用axios对应的后台上传图片接口
      let result = await faceLogin.faceLogin(formData);
      console.log(result);
      if (result.data.code == 200) {
        // localStorage.setItem("username", result.data.data.username);
        this.$message({
          message: "人脸识别成功",
          type: "success",
          center: true,
          showClose: true,
        });
        that.$router.push({ path: "/login2" });
      } else {
        this.$message({
          message: "登录失败,图像不合格",
          center: true,
          type: "error",
          showClose: true,
        });
      }
      return false;
    },

    // 调用权限（打开摄像头功能）
    getCompetence() {
      let that = this;
      that.thisCancas = document.getElementById("canvasCamera");
      that.thisContext = this.thisCancas.getContext("2d");
      that.thisVideo = document.getElementById("videoCamera");
      that.thisVideo.style.display = "block";
      // 获取媒体属性，旧版本浏览器可能不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      // 一些浏览器实现了部分mediaDevices，我们不能只分配一个对象
      // 使用getUserMedia，因为它会覆盖现有的属性。
      // 这里，如果缺少getUserMedia属性，就添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          // 首先获取现存的getUserMedia(如果存在)
          var getUserMedia =
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.getUserMedia;
          // 有些浏览器不支持，会返回错误信息
          // 保持接口一致
          if (!getUserMedia) {
            //不存在则报错
            return Promise.reject(
              new Error("getUserMedia is not implemented in this browser")
            );
          }
          // 否则，使用Promise将调用包装到旧的navigator.getUserMedia
          return new Promise(function (resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }
      var constraints = {
        audio: false,
        video: {
          width: this.videoWidth,
          height: this.videoHeight,
          transform: "scaleX(-1)",
        },
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function (stream) {
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in that.thisVideo) {
            that.thisVideo.srcObject = stream;
          } else {
            // 避免在新的浏览器中使用它，因为它正在被弃用。
            that.thisVideo.src = window.URL.createObjectURL(stream);
          }
          that.thisVideo.onloadedmetadata = function (e) {
            console.log(e);
            that.thisVideo.play();
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //  第二步、绘制图片（拍照功能）
    setImage() {
      let that = this;
      // canvas画图
      that.thisContext.drawImage(that.thisVideo, 0, 0);
      // 获取图片base64链接
      let image = this.thisCancas.toDataURL("image/png");
      that.imgSrc = image; //赋值并预览图片
      // console.log(that.imgSrc,8889);
      //这里是调用上传图片接口=====
      this.postImg(); // 绘制完图片调用图片上传接口
    },
    // 关闭摄像头
    stopNavigator() {
      this.thisVideo.srcObject.getTracks()[0].stop();
    },

    // base64 转为 file
    base64ToFile(urlData, fileName) {
      let arr = urlData.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]); // 解码base64
      let n = bytes.length;
      let ia = new Uint8Array(n);
      while (n--) {
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia], fileName, { type: mime });
    },
    loginHandle() {
      let that = this;
      that.$router.push({ path: "/login1" });
      // if (that.username == '管理员') {
      //   //如果为管理员
      //   that.$router.push({ path: "/" });
      // } else if (that.username =='值班员1' ) {
      //   that.$router.push({ path: "/" });
      // } else if (that.username == '值班员2') {
      //   that.$router.push({ path: "/taskList" });
      // }else if (that.username =='值班员3') {
      //   that.$router.push({ path: "/checkList" });
      // }
    },
  },
  destroyed: function () {
    // 离开当前页面
    this.stopNavigator(); // 关闭摄像头
  },
};
</script>
<style lang='scss'  scoped>
@import "@/scss/login/face.scss";
</style>
